@media screen and (min-width: 300px) {
  .MuiPickersPopper-root {
    transform: translate(50px, 140px) !important;
  }
}

@media screen and (min-width: 350px) {
  .MuiPickersPopper-root {
    transform: translate(20px, 140px) !important;
  }
}

@media screen and (min-width: 400px) {
  .MuiPickersPopper-root {
    transform: translate(50px, 140px) !important;
  }
}

@media screen and (min-width: 1000px) {
  .MuiPickersPopper-root {
    transform: translate(324px, 140px) !important;
  }
}
