body {
  margin: 0;
}
.section::-webkit-scrollbar {
  width: 10px;
}

.section::-webkit-scrollbar-track {
  background-color: darkgrey;
}

.section::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
